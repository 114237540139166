import React from 'react';
import { FaFacebook } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { IoMailUnread } from "react-icons/io5";
import { MdPhoneInTalk } from "react-icons/md";
import { BiArrowToRight } from "react-icons/bi";
import ImageLoading from './ImageLoading';
import { Link } from 'react-router-dom';


function FooterSection() {
   return (
      <section className="bg-black footer">
         <div className="wrapper">
            <div className="center-info">
                <h1 className="title-2">Não Espere Mais</h1>
                <Link to="/request_budget">
                   <button className="btn bg-main text-white">Compre agora</button>
                </Link>
            </div>
            <div className="footer-main-content">
               <div className="item about-item">
                  <ImageLoading height={60} source="https://cassianos.2smart.pt/images/logo/login-hrm.svg" alt="" />
                  <p className='text-light'>O 2Smart Cloud é uma Plataforma de Gestão de Assiduidades e Acessos acessivel através de um browser. Especialmente concebida para empresas que não pretendem soluções On-premise ou terem custos adicionais em Infra-estrutura e manutenção de IT.</p>
                  <div className="social-icons"> 
                     <Link to="#" target="_blank" rel="noopener noreferrer"><FaFacebook /></Link>
                     <Link to="#" target="_blank" rel="noopener noreferrer"><SiLinkedin /></Link>
                     <Link to="mailto:helpdesk@exportech.com.pt"><IoMailUnread /></Link> 
                     <Link to="tel:+351291601603"><MdPhoneInTalk /></Link>
                  </div>
               </div>
               <div className="item links">
                  <h4>Sobre a empresa</h4>
                  <ul>
                     <li><Link to="#" target="_blank" rel="noopener noreferrer">Sobre nós</Link></li>
                     <li><Link to="#" target="_blank" rel="noopener noreferrer">Produtos</Link></li>
                     <li><Link to="#" target="_blank" rel="noopener noreferrer">Localizações</Link></li> 
                  </ul>
               </div>
               <div className="item links">
                  <h4>Navegação</h4>
                  <ul>  
                     <li><Link to="/request_budget">Solicitar orçamento</Link></li>
                     <li><Link to="/contacts">Contactos</Link></li>
                     <li><Link to="/successfuly_stories">Casos de sucesso</Link></li>
                     <li><Link to="https://www.livroreclamacoes.pt/Inicio/"   target="_blank" rel="noopener noreferrer">Livro de reclamações</Link> </li>
                  </ul>
               </div>
            </div>
            <div className="space-items">
               <div>
                  <p>Copyright 2024 2Smart | Desenvolvido por
                     <Link to="https://exportech.com.pt"  target="_blank" rel="noopener noreferrer"> Exportech Portugal</Link>
                  </p>
               </div>
              <div>
                 <BiArrowToRight />
                 <p> Nossa Política Comercial, <Link to="" target="_blank" rel="noopener noreferrer"> Termos e Condições</Link></p>
              </div>
            </div>
         </div>
      </section>
   )
}

export default FooterSection
