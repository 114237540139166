import React from 'react'
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaStar } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { SiLinkedin } from "react-icons/si";
import ImageLoading from './ImageLoading';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function TestimonialsSection() { 

  const Data = [ 
    {
      description:"2smart é um aplicativo de RH, atribuição de tarefas e rastreamento de projetos, garantindo que as equipes se mantenham organizadas e cumpram os prazos.",
      image:"https://static.vecteezy.com/system/resources/thumbnails/005/346/410/small_2x/close-up-portrait-of-smiling-handsome-young-caucasian-man-face-looking-at-camera-on-isolated-light-gray-studio-background-photo.jpg",
      name:"José Miguel",
      charge:"CEO da grupo Boticario",
    },   
    {
      description:"2smart é um aplicativo de RH, atribuição de tarefas e rastreamento de projetos, garantindo que as equipes se mantenham organizadas e cumpram os prazos.",
      image:"https://as2.ftcdn.net/v2/jpg/03/78/86/31/1000_F_378863101_2o6YnBqX5uiHnLpmESJWU3yTxDckVkkO.jpg",
      name:"Samira Manuel",
      charge:"CTO no PingoDoce",
    },  
    {
      description:"2smart é um aplicativo de RH, atribuição de tarefas e rastreamento de projetos, garantindo que as equipes se mantenham organizadas e cumpram os prazos.",
      image:"https://thumbs.dreamstime.com/b/portrait-young-brutal-african-american-man-applying-facial-cream-his-cheek-close-up-portrait-men-s-beauty-skin-care-portrait-185903653.jpg",
      name:"Patricio Lopes",
      charge:"Tecnico de RH na Axians",
    },   
    {
      description:"2smart é um aplicativo de RH, atribuição de tarefas e rastreamento de projetos, garantindo que as equipes se mantenham organizadas e cumpram os prazos.",
      image:"https://st4.depositphotos.com/1591576/20942/i/450/depositphotos_209420114-stock-photo-close-horizontal-portrait-stubble-bearded.jpg",
      name:"Carlos Manuel",
      charge:"Gestor de stock na Worten",
    },   
  ];



  return (
    <section className='testimonials-section'>
      <div className="wrapper">
        <div className="content">
          <Slider arrows={true}  responsive={responsive}>
            {Data.map((item, index) => (
                     <article key={index} >
                      <div className={`${index%2 !== 0 ? "info-area " : "info-area bg-section" }`}>
                      <div className="dets">
                      <h3 className="description-4">Serviços Fascinantes !!</h3>
                        <br />
                        <p className="description-1">{item.description}</p>
                         <div className="flex-info">
                            <ImageLoading height={50} source={item.image} /> 
                             <div className="det">
                                 <h3 className="description-4">{item.name}</h3>
                                 <h3 className="description-1"><small>{item.charge}</small></h3>
                             </div> 
                        </div>
                        </div>
                      </div> 
                     </article>
           ))}
          </Slider>
          <br /><br />
          <div className="center">
             <div className="box-rat">
               <div className="icon"><FcGoogle /></div>
              <div className="block-el">
              <span className="description-4">Avaliação do Google</span> 
               <div className="flex-el">
                  <strong className="description-4">5.0</strong>
                  <div className="el"><FaStar /></div>
                  <div className="el"><FaStar /></div>
                  <div className="el"><FaStar /></div>
                  <div className="el"><FaStar /></div>
                  <div className="el"><FaStar /></div>
               </div>
              </div>
             </div>
             <div className="box-rat">
               <div className="icon"><SiLinkedin /></div>
              <div className="block-el">
              <span className="description-4">Avaliação do Linkedin</span> 
               <div className="flex-el">
                  <strong className="description-4">145.0 recomendações</strong> 
               </div>
              </div>
             </div>
          </div>
          <br />
          <p className='description-1' >Classificação total 5,0 com base em 650 avaliações</p>
        </div>
      </div>
    </section>
  )
}

export default TestimonialsSection
