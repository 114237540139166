import React, { Fragment, useState } from "react";
import { useAlert } from "react-alert";
import Navbar from '../Components/Navbar';
import FooterSection from '../Components/FooterSection';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImageLoading from '../Components/ImageLoading';
import SendEmail from "../Components/SendEmail"; 

 
function RequestBudget() {
    document.title = "Solicitar orçamento";
    const [ClientName, setClientName] = useState(null);
    const [ClientEmail, setClientEmail] = useState(null);
    const [ClientCompany, setClientCompany] = useState(null);
    const [ClientSector, setClientSector] = useState(null);
    const [ClientNif, setClientNif] = useState(null);
    const [ClientMessage, setClientMessage] = useState(null);
    const [ClientPhone, setClientPhone] = useState(null); 
    const [ClientCheckTerms, setClientCheckTerms] = useState(false); 
    const alert = useAlert();

     function SubmitForm(e){
        e.preventDefault();  
        alert.success("Mensagem enviada com sucesso !");
        SendEmail(ClientNif, ClientSector, ClientCompany , ClientName, ClientPhone, ClientEmail, ClientMessage);
     } 

    return (
        <Fragment> 
            <div className='request-budget'>
                <Navbar active={8} black />
                <div className="wrapper">
                    <div className="title-area">
                        <h1 className="title-1">Solicitar orçamento</h1>
                    </div>
                </div>
                <div className="form-zone">
                    <div className="wrapper">
                        <div className="content">
                            <div className="images-area">
                                <div className="image">
                                    <ImageLoading source={"https://images.pexels.com/photos/5900181/pexels-photo-5900181.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
                                </div>
                            </div>
                            <div className="form-area">
                                <div className="form-box">
                                    <Form onSubmit={SubmitForm}>
                                        <div className="center">
                                            <h1 className="title-3">Fale Connosco </h1> <br />
                                            <small className='description-1'>
                                                Preencha o formulário abaixo. Um consultor irá entrar em contacto consigo para dar a melhor resposta ao seu pedido de proposta,
                                                reunião de consultoria, demonstração de software ou diagnóstico aprofundado de necessidades.
                                            </small>
                                        </div>
                                        <div className="box-inputs mt-3">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control  onChange={(e)=>setClientName(e.target.value)} onPaste={(e)=>setClientName(e.target.value)}  type="text" placeholder="..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email </Form.Label>
                                                <Form.Control onChange={(e)=>setClientEmail(e.target.value)} onPaste={(e)=>setClientEmail(e.target.value)}  type="email" placeholder="..." />
                                            </Form.Group>
                                        </div>
                                        <div className="box-inputs mt-3">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Empresa</Form.Label>
                                                <Form.Control onChange={(e)=>setClientCompany(e.target.value)} onPaste={(e)=>setClientCompany(e.target.value)}  type="text" placeholder="..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Telemovel</Form.Label>
                                                <Form.Control onChange={(e)=>setClientPhone(e.target.value)} onPaste={(e)=>setClientPhone(e.target.value)}  type="text" placeholder="..." />
                                            </Form.Group>
                                        </div>
                                        <div className="box-inputs col mt-3">
                                            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                                <Form.Label>Número de colaboradores na empresa</Form.Label>
                                                <Form.Control type="number"  placeholder="..." />
                                            </Form.Group> 
                                        </div>
                                        <div className="box-inputs mt-3">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Nif</Form.Label>
                                                <Form.Control onChange={(e)=>setClientNif(e.target.value)} onPaste={(e)=>setClientNif(e.target.value)}  type="..." placeholder="..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Sector</Form.Label>
                                                <Form.Select onChange={(e)=>setClientSector(e.target.value)} onPaste={(e)=>setClientSector(e.target.value)} >
                                                    <option value="">Setor *</option>
                                                    <option value="Agroalimentar">Agroalimentar</option>
                                                    <option value="Comércio">Comércio</option>
                                                    <option value="Construção">Construção</option>
                                                    <option value="Consultoria">Consultoria</option>
                                                    <option value="Contabilidade">Contabilidade</option>
                                                    <option value="Educação">Educação</option>
                                                    <option value="Energia">Energia</option>
                                                    <option value="Hotelaria">Hotelaria</option>
                                                    <option value="Imobiliário">Imobiliário</option>
                                                    <option value="Indústria">Indústria</option>
                                                    <option value="Logística/Distribuição">Logística/Distribuição</option>
                                                    <option value="Marketing/Publicidade">Marketing/Publicidade</option>
                                                    <option value="Notariado">Notariado</option>
                                                    <option value="Saúde e Estética">Saúde e Estética</option>
                                                    <option value="Setor Público">Setor Público</option>
                                                    <option value="Tecnologia">Tecnologia</option>
                                                    <option value="Telecomunicações">Telecomunicações</option>
                                                    <option value="Turismo e Lazer">Turismo e Lazer</option>
                                                    <option value="Outro">Outro</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="flex-item">
                                            <Form.Check onChange={(e)=>setClientCheckTerms(e.target.value)} type={"checkbox"} id={`default-1`} label={`* Concordo com o tratamento dos meus dados de acordo com  os `} />
                                            <Link to="#" target='_blank' ><strong>Termos e Condições</strong></Link>
                                        </div>
                                        <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Mensagem</Form.Label>
                                            <Form.Control onChange={(e)=>setClientMessage(e.target.value)} onPaste={(e)=>setClientMessage(e.target.value)}  placeholder='...' as="textarea" rows={6} />
                                        </Form.Group>
                                        <button className="btn bg-main text-light">Enviar</button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <FooterSection />
            </div>
        </Fragment>

    )
}

export default RequestBudget


