import React from 'react'
import { FcElectronics } from "react-icons/fc";
import ImageLoading from './ImageLoading';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { LiaCogSolid } from "react-icons/lia";
import { LuCalendarDays } from "react-icons/lu";
import { TiChartPieOutline } from "react-icons/ti";
import { CgMenuMotion } from "react-icons/cg";
import { HiOutlineDocumentChartBar } from "react-icons/hi2";
import { GiProgression } from "react-icons/gi";
import Table from 'react-bootstrap/Table';
import { TbCertificate } from "react-icons/tb";


function IntegrationSection() {
  const Data = [
    {
      icon: "https://innux.pt/wp-content/uploads/2014/01/-eticadata-logo-500x238.png?500x238",
      title: "Eticadata",
      label: "software de gestão empresarial",
    },
    {
      icon: "https://innux.pt/wp-content/uploads/2014/01/-artsoftbusinesssoftware-horizontal-500x168.png?500x168 ",
      title: "Artsoft",
      label: "Software de gestão de contabilidade ",
    },
    {
      icon: "https://www.calltrackingmetrics.com/wp-content/uploads/2019/02/DYNAMICS.png",
      title: "Microsoft Dynamics",
      label: "Software de  gestão corporativa ERP",
    },
    {
      icon: "https://gestware.pt/wp-content/uploads/2016/07/logo-gestware.png",
      title: "Gestware",
      label: "Software de gestão empresarial,",
    },
    {
      icon: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Sage_logo.png",
      title: "SAGE",
      label: "Software de gestão de negocios",
    },
    {
      icon: "https://storage.landing.jobs/zzDDnXr5fSBoM6a3iwiUS9te ",
      title: "Primavera",
      label: "Software de gerenciamento de projetos corporativos",
    },
    {
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/SAP_2011_logo.svg/2560px-SAP_2011_logo.svg.png ",
      title: "SAP",
      label: "Sistema integrado de gestão empresarial ",
    },

    {
      icon: "https://www.himss.org/sites/hde/files/styles/f2f_sponsor_full_logo_xs_sm/public/media/image/2023/05/24/f3m.png?itok=aKdKEhf4",
      title: "F3M",
      label: "Software para mercados verticais",
    },
    {
      icon: "https://www.solutions-ressources-humaines.com/logo/d3f2f8db09aec60logo_meta4_sans_baseline.jpg",
      title: "Meta4",
      label: " Solução de Gestão de Assiduidade",
    },
    {
      icon: "https://cdn.prod.website-files.com/6342ebadeb3b664be3039230/6567532a2b6dbe4beb59a8cb_PHC%20(logo).png",
      title: "PHC",
      label: "Software de gestão empresarial",
    },
    {
      icon: "https://pq-media.imgix.net/l/312dede68873e3ece83457fde0a22390.jpg?w=200&auto=compress&fit=fill&fill=solid&fill-color=ffffff&h=200",
      title: "Sendys",
      label: "Software de gestão de negócios & empresas",
    },
    {
      icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKcmlNJkmvsfoztdPsUBGEUeKls-XRXYdxCw&s ",
      title: "Medidata",
      label: "Sistemas de Informação para Autarquias",
    },
    {
      icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl2NGkCJQjMwnZ55Hc_kRZXbYVfemJpxjVeQ&s",
      title: "Alidata",
      label: "Ferramenta essencial para otimizar operações ",
    },
    {
      icon: "https://innux.pt/wp-content/uploads/2014/01/-airc-fundobranco-logocorcoorporativa-500x195.png?500x195",
      title: "Airc",
      label: "Software de gestão de negócios & empresas",
    },
  ];
  //    <ImageLoading source={item.icon} alt={item.title} />
  //  <ImageLoading height={70} source={item.icon} alt="" />

  return (
    <section className='integration-section'>
      <div className="wrapper">
        <div className="content">
          <div className="center">
            <h1 className="title-2">Integração de Todas as Ferramentas</h1>
            <p className="description-2">
              Integração perfeita com as ferramentas de gestão mais eficientes do mercado.
            </p>
          </div>

          <div className="content-area">
            <aside className="animated-tree">
              <div className="rotate-items">
                {Data.map((item, index) => (
                  <div className={`item item-${index + 1}`} key={index + 1}>
                    <ImageLoading height={70} source={item.icon} alt="" />
                  </div>
                ))}
              </div>

              <aside className="mobile-frame">
                <div class='mobile-phone'>
                  <div class='brove'><span class='speaker'></span></div>
                  <div class='screen'>
                    <div className="screen-page screen-dashboard">
                      <aside className="main-box">
                        <div className="header-dash">
                          <div className="space-items">
                            <div className="logo">
                              <ImageLoading height={45} source={require("../Assets/Images/logo2.png")} />
                            </div>
                            <div className="profile-itens">
                              <div className="icon"><CgMenuMotion /></div>
                              <div className="icon"><LiaCogSolid /></div>
                              <div className="avatar"><ImageLoading source="https://www.man.com/sites/default/files/uploads/content/team/Large_Barry-Fitzgerald2.jpg" height={45} /></div>
                            </div>
                          </div>
                          <div className="page-boxes">
                            <div className="col-lg-12">
                              <h5 className='text-light'>Bem-Vindo Marcelo </h5>
                            </div>
                            <div className="box box-orange">
                              <div className="chart-box">
                                <div className="status">45%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Extras</div>
                            </div>
                            <div className="box box-green">
                              <div className="chart-box">
                                <div className="status">15%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Faltas Parciais</div>
                            </div>
                            <div className="box box-teal">
                              <div className="chart-box">
                                <div className="status">34%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Ausências</div>
                            </div>
                            <div className="box box-red">
                              <div className="chart-box">
                                <div className="status">87%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Baixas</div>
                            </div>
                          </div>
                          <br /><br /><br /><br />
                          <br /><br /><br /><br />
                          <div className="box box-lg ">
                            <div className="title-item"><TbCertificate /> licenciamento</div>
                            <Table size="lg" >
                              <tbody>
                                <tr>
                                  <td className='flex-item'> <strong>30</strong> Licenças</td>
                                </tr>
                                <tr>
                                  <td className='flex-item'><strong>27 Activas</strong></td>
                                </tr>
                                <tr>
                                  <td className='flex-item'><strong>01</strong> Histórico</td>
                                </tr> 
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <div className="menu-nav">
                          <div className='active'>
                            <LuCalendarDays />
                          </div>
                          <div>
                            <HiOutlineDocumentChartBar />
                          </div>
                          <div className='main-icon'>
                            <TiChartPieOutline />
                          </div>
                          <div>
                            <GiProgression />
                          </div>
                          <div>
                            <LuCalendarDays />
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </aside>
 
            </aside>
            <div className="text-area">
              <ul>
                {Data.map((item, index) => (
                  <li>
                    <div className="icon">
                        <IoIosCheckmarkCircleOutline /> 
                        <strong>{item.title}</strong>
                    </div>
                     <span> <strong> - </strong>{item.label}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div> 

        </div>
      </div>
    </section>
  )
}

export default IntegrationSection
