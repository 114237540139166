import React from 'react'
import { GrCloudDownload } from "react-icons/gr";
import { PiUsersThree } from "react-icons/pi";
import { PiBuildings } from "react-icons/pi";


function FactsSection() {
  return (
    <section className='facts-section bg-section'>
         <div className="wrapper">
             <div className="content">
                  <article>
                    <div className="icon bg-main text-white"><GrCloudDownload /></div>              
                     <div className="block-info">
                        <h4 className='title-3'>+340.K</h4>    
                        <p className="description-3">Total Downloads</p> 
                    </div>
                  </article>
                  <article>
                    <div className="icon bg-main text-white"><PiUsersThree /></div>              
                     <div className="block-info">
                        <h4 className='title-3'>+24.K</h4>    
                        <p className="description-3">Total usúarios</p> 
                    </div>
                  </article>
                  <article>
                    <div className="icon bg-main text-white"><PiBuildings /></div>              
                     <div className="block-info">
                        <h4 className='title-3'>+500</h4>    
                        <p className="description-3">Total clientes</p> 
                    </div>
                  </article>
             </div>
             <div className="bar"></div>
         </div>
    </section>
  )
}

export default FactsSection
