import React from 'react'
import ImageLoading from '../ImageLoading'
import { FaStar } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { ImQuotesRight } from "react-icons/im";

function TestimonialHeader() {
  return (
    <div className=' testimonial-header'>
      <div className="wrapper">
          <div className="content">
            <div className="over-image">
             <ImageLoading source={"https://static.vecteezy.com/system/resources/previews/022/183/274/non_2x/hand-drawn-doodle-curved-line-clip-art-free-png.png"}  height={1} />
            </div>
               <div className="text-area">
                   <h1 className='title-2'>Lideres e colaboradores de varias empresas  Amam o 2Smart</h1>
                   <p className='description-1 p1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur expedita similique exercitationem autem facere totam?</p>
                   <hr />
                   <div className="test-box">
                      <div className="stars">
                         <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                      </div>
                      <p className='description-2'>
                         Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur, saepe. Error soluta velit debitis, dolores libero quidem sequi perspiciatis quaerat! Autem vero similique ducimus vitae.
                      </p>
                      <div className="flex-item">
                          <div className="pic">
                             <ImageLoading   source={"https://mensline.org.au/wp-content/uploads/2020/05/Excuses-header-1024x683.jpg"} height={1} />
                          </div>
                          <div className="block">
                            <div><strong>Rau Mateus</strong></div> 
                            <span>Executivo no mini-preço</span>
                          </div>
                      </div>
                      <div className="quotes">
                          <ImQuotesRight />
                      </div>
                   </div>
               </div>
               <div className="image-box-container">
                   <div className="image">
                      <ImageLoading  source={"https://images.pexels.com/photos/6567336/pexels-photo-6567336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} height={400} />
                      <div className="image-over">
                         <div className="top-header"></div>
                         <div className="logo"><SiLinkedin /></div>
                         <span>4.9 Linkedin</span>
                         <div className="stars">
                         <FaStar />  <FaStar />    <FaStar />  <FaStar />  <FaStar />
                        </div>
                      </div>
                   </div>
               </div>
          </div>
      </div>
    </div>
  )
}

export default TestimonialHeader
