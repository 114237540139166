import React from 'react'
import { IoArrowBackOutline } from "react-icons/io5";

function NotFounded() {
    document.title = "Pagina não encontrada";
    return (
        <div className='not-founded-page'>
            <div className='info'>
                <h1 className="title-1 text-white">404</h1> <br />
                <p className="description-1">Lamentamos mas não foi possivel encontrar está pagina </p> <br />
                <a href="#"><button className="btn bg-main"> <IoArrowBackOutline /> Voltar a pagina inicial</button></a>
            </div>
        </div>
    )
}

export default NotFounded
