import React from 'react'
import { LuCheckCircle2 } from "react-icons/lu";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PiContactlessPaymentLight } from "react-icons/pi";
import { PiStarFourFill } from "react-icons/pi";
import ImageLoading from './ImageLoading';
import { Link } from 'react-router-dom';

function PriceSection() {
    const item  =
        {
            title: "2Smart HRM",
            price: "63€",
            class: "text-dark",
            options: [
                { name: "Gestão financeira dos colaboradores", enable: true },
                { name: "Controle e gestão de visitas", enable: true },
                { name: "Relatorios e analise da empresa", enable: true },
                { name: "Analíse de dados dinamicos", enable: true },
                { name: "Controle de faltas dos colaboradores", enable: true },
                { name: "Integração com ferramentas de gestão", enable: true },
                { name: "SMS e Emails personalizados", enable: true },
                { name: "Gestão de Visitas", enable: true }, 
            ]
        }



    return (
        <section className="prices-section">
            <div className="wrapper">
                <div className="title-info">
                    <h1 className="title-2">Escolha o seu pacote de preços favorito</h1>
                    <p className="description-1">
                        Integração perfeita com ferramentas de gestão e sistemas  electrónicos.
                    </p>
                </div>
                <div className="content"> 
                        <article className={`${item.class}`}>
                            <div className="dets">
                                <div className="space-item">
                                    <div><h5>{item.title}</h5><h1> Preço sob consulta</h1></div>
                                    <div>
                                        <Link to="/request_budget">
                                            <button className="btn bg-main text-light col-lg-12">Comprar Agora</button>
                                        </Link>
                                    </div>
                                </div>
                                <hr />
                                <ul>
                                    {item.options.map((opt) => (
                                        <li className='description-1'>
                                            {opt.enable === false ? <AiOutlineCloseCircle /> : <LuCheckCircle2 />}
                                            {opt.enable ? <>{opt.name}</> : <s>{opt.name}</s>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="box-image">
                                <div className="box-over">
                                    <div className="cards">
                                        <div className="card card-green">
                                            <div className="header-card">
                                                <img src="https://static.vecteezy.com/system/resources/thumbnails/013/374/265/small_2x/white-light-curve-line-modern-gradient-background-wavy-lines-light-gradient-white-background-vector.jpg" />
                                                <div className="det-card">
                                                    <div className="icon"><PiContactlessPaymentLight /></div>
                                                    <div><h3>Exportech portugal </h3></div>
                                                </div>
                                            </div>
                                            <div className="det-card">
                                                <br />
                                                <div><strong>EXP</strong></div>
                                            </div>
                                        </div>
                                        <div className="card bg-main card-blue">
                                            <img src="https://i.pinimg.com/1200x/11/86/6d/11866d826d4c87fe3357d1c48ca3e67c.jpg" alt="" />
                                            <div className="det-card">
                                                <div className="icon"><PiContactlessPaymentLight /></div>
                                                <h3>Exportech portugal </h3>
                                                <div> <span>Network</span></div>
                                                <div><strong>ERC-20</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dt-box">
                                    <div>
                                        <div className="avatars">
                                            <div className="avt">
                                                <ImageLoading source={"https://www.man.com/sites/default/files/uploads/content/team/Large_Barry-Fitzgerald2.jpg"} />
                                            </div>
                                            <div className="avt lg">
                                                <ImageLoading source={"https://www.montclair.edu/responsive-media/cache/newscenter/wp-content/uploads/sites/15/2024/03/082223_16686_Dr-Marcheta-Evans-1-1.jpg.1.2x.generic.jpg"} />
                                            </div>
                                            <div className="avt">
                                                <ImageLoading source={"https://img.freepik.com/fotos-gratis/jovem-barbudo-com-camisa-listrada_273609-5677.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1725148800&semt=ais_hybrid"} />
                                            </div>
                                        </div>
                                        <p>A melhor plataforma de recursos humanos acessivel para todos</p>
                                        <Link to="/contacts">Entrar em contacto</Link>
                                    </div>
                                </div>
                            </div>
                        </article> 
                </div>
            </div>
        </section>
    )
}

export default PriceSection
