import React from 'react'
import { TbHeartRateMonitor } from "react-icons/tb";
import { FiArrowUpRight } from "react-icons/fi";
import ImageLoading from './ImageLoading';
import { AiOutlineDashboard } from "react-icons/ai";
import { GrGroup } from "react-icons/gr";
import { PiLightbulbFilamentLight } from "react-icons/pi";
import { LuBadgeX } from "react-icons/lu";
import { MdOutlineTimer } from "react-icons/md";
import { RiFlashlightLine } from "react-icons/ri";
import { TbReport } from "react-icons/tb";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { PiLightbulbFilamentBold } from "react-icons/pi";
import { Link } from 'react-router-dom';

function FeaturesSection() {

   const Data = [

      {
         icon: <AiOutlineDashboard />,
         title: "Dashboard",
         desciption: `No dashboard poderá visualizar a informação geral da aplicação. Esta é uma área que agrega
         vários blocos de informação não só a nível informativo, mas   estatístico`
      },
      {
         icon: <GrGroup />,
         title: "Colaboradores",
         desciption: `No menu colaboradores reúnem-se todas as funcionalidades que servem para fazer a gestão da  
         informação relativa aos mesmos`
      },
      {
         icon: <PiLightbulbFilamentBold />,
         title: "Planificação",
         desciption: `O menu Planificação inclui todos os itens que tenham a ver com horários, atribuição de horários e marcações de
         férias dos colaboradores.`
      },
      {
         icon: <LuBadgeX />,
         title: "Absenteísmo",
         desciption: `A vista de absentismo fornece uma visão global de todas as faltas existentes em sistema.`
      },
      {
         icon: <MdOutlineTimer />,
         title: "Banco de Horas",
         desciption: `No Banco de Horas, caso a empresa trabalhe com o mesmo é possível consultar o saldo de horas em
         banco de horas após as horas extras efetuadas pelos colaboradores`
      },
      {
         icon: <RiFlashlightLine />,
         title: "Produtividade",
         desciption: `A vista de produtividade, permite-nos, por exemplo analisar como correu determinado mês em termos
         de produtividade para determinado colaborador, por exemplo`
      },
      {
         icon: <TbReport />,
         title: "Relatórios",
         desciption: `A partir do menu relatórios é possível efetuar a geração de uma série de relatórios. Os documentos gerados surgem em formato de lista. `
      },
      {
         icon: <TbBuildingSkyscraper />,
         title: "Empresas",
         desciption: `É a partir do menu empresas que o utilizador consegue visualizar a informação relativa á empresa. para  alterar os dados deverá solicitar a alteração da mesma ao RH
         ou Admin.`
      },
   ];


   return (
      <section className='key-features bg-section'>
         <div className="wrapper">
            <div className="content">
               <div className="center">
                  <h1 className="title-2">Recursos Principais</h1>
                  <p className='description-1'>Esses recursos contribuem coletivamente para um projeto completo Sistema de gestão que ajuda as equipes a planejar e executar com eficiência.</p>
               </div>
               <div className="wrap-items">
                  {Data.map((item, index) => (
                     <div className={`box box-${index+1 }`}>
                        <article key={index}>
                        <div className='dets tt'><strong className='title-3'>{item.title}</strong></div>
                        <div className="icon-content">
                           <div className="icon-container">
                              <div className="icon">{item.icon}</div>
                           </div>
                        </div>
                        <div className="dets">
                           <p className="description-1">{item.desciption}</p>
                        </div>
                      </article>
                     </div>
                  ))}
               </div>
            </div>
            <div className="center">
               <div className="contact-link">
                  <p className="description-1">Tem alguma dúvida?</p>
                  <Link to="/contacts"><strong>Entre em contato agora <FiArrowUpRight /></strong> </Link>
               </div>
            </div>
         </div>
      </section>
   )
}

export default FeaturesSection
