import React from 'react'
import Navbar from '../Components/Navbar'
import Header from '../Components/Header'
import CustomersSection from '../Components/CustomersSection'
import Assistent from '../Components/Assistent';
import IntegrationSection from '../Components/IntegrationSection';
import PerformanceSection from '../Components/PerformanceSection';
import TestimonialsSection from '../Components/TestimonialsSection';
import FactsSection from '../Components/FactsSection';
import PriceSection from '../Components/PriceSection';
import FooterSection from '../Components/FooterSection';
import FeaturesSection from '../Components/FeaturesSection';
import ScrollToTop from '../Components/ScrollToTop';

function Home() {
  document.title = "2smart";
  
  return (
    <div>
      <ScrollToTop />
        <Navbar active={1} />
        <Header />
        <CustomersSection />
        <FeaturesSection />
        <Assistent />
        <IntegrationSection />
        <TestimonialsSection />
        <PerformanceSection />
        <FactsSection />
        <PriceSection />
        <FooterSection /> 
    </div>
  )
}

export default Home
