import React, { useState } from 'react'
import { IoIosTrendingDown } from "react-icons/io";
import { LuUser2 } from "react-icons/lu";
import ImageLoading from './ImageLoading';
import Dropdown from 'react-bootstrap/Dropdown';
import { GrLanguage } from "react-icons/gr";
import { LiaCogSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import { CiMenuFries } from "react-icons/ci";
import { IoClose } from "react-icons/io5";

function ToggleLanguage() {
  return (
    <div className="toggle-language">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <div className="icon"><GrLanguage /></div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div>
            <Dropdown.Item href="#" className='active-lk' >
              <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/640px-Flag_of_Portugal.svg.png" />Português</div>
            </Dropdown.Item>
          </div>
          <div >
            <Dropdown.Item href="#" >
              <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/2048px-United-kingdom_flag_icon_round.svg.png" />Inglês</div>
            </Dropdown.Item>
          </div>
          <div>
            <Dropdown.Item href="#">
              <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/1200px-Bandera_de_Espa%C3%B1a.svg.png" />Espanhol</div>
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}


function Navbar({ black, active }) {
  const [show, setShow] = useState(false);
  return (
    <div className={`navbar-area  ${black === true ? "black" : ""}`}>
      <div className="wrapper">
        <div className="navbar-items">
          <Link to="/">
            <div className="logo">
              <ImageLoading height={60} source={black !== true ? "https://cassianos.2smart.pt/images/logo/login-hrm.svg" : require("../Assets/Images/2smart.png")} alt="" />
            </div>
          </Link>
          <ul className="links">
            <li><Link className={active === 1 ? "active" : ""} to="/">Inicio <div className="icon"><IoIosTrendingDown /></div></Link></li>
            <li><Link className={active === 3 ? "active" : ""} to="/successfuly_stories">Casos de sucesso <div className="icon"><IoIosTrendingDown /></div></Link></li>
            <li><Link className={active === 4 ? "active" : ""} to="/contacts">Contactos<div className="icon"><IoIosTrendingDown /></div></Link></li>
          </ul>
          <div className="links">
            <Link to="/request_budget"><button className={`btn  ${black === true ? "bg-main text-white" : "bg-white"}`}>Solicitar Orçamento</button></Link>
            <div className="toggle-language">
              <ToggleLanguage />
            </div>
          </div>

          <div className="responsive-menu">
            <div className="toggle-menu t1" onClick={() => setShow(show === false ? true : false)}><CiMenuFries /></div>
            <menu className={show === true ? "show" : ""}>
              <div className="space">
                <div className="item">
                  <h4 className="title">MENU</h4>
                </div>
                <div className="item">
                  <div className="toggle-menu" onClick={() => setShow(show === false ? true : false)}><IoClose /></div>
                </div>
              </div>
              <div className="center">
                <li onClick={() => setShow(show === false ? true : false)}><Link className={active === 1 ? "active" : ""} to="/">Inicio <div className="icon"><IoIosTrendingDown /></div></Link></li>
                <li onClick={() => setShow(show === false ? true : false)}><Link className={active === 3 ? "active" : ""} to="/successfuly_stories">Casos de sucesso <div className="icon"><IoIosTrendingDown /></div></Link></li>
                <li onClick={() => setShow(show === false ? true : false)}><Link className={active === 4 ? "active" : ""} to="/contacts">Contactos<div className="icon"><IoIosTrendingDown /></div></Link></li>
                <div onClick={() => setShow(show === false ? true : false)}>
                  <Link to="/request_budget"><button className={`btn  ${black === true ? "bg-main text-white" : "bg-white"}`}>Solicitar Orçamento</button></Link>
                </div>
                <div className="toggle-language">
                  <ToggleLanguage />
                </div>
              </div>
            </menu>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;
