import React from 'react'
import FooterSection from '../Components/FooterSection';
import Navbar from '../Components/Navbar';
import TestimonialHeader from '../Components/TestimonialComponents/TestimonialHeader';
import TestimonialClient from '../Components/TestimonialComponents/TestimonialClient';
import TestimonialVideos from '../Components/TestimonialComponents/TestimonialVideos';
import TestimonialsSection from '../Components/TestimonialsSection';
import TextimonialExtra from '../Components/TestimonialComponents/TextimonialExtra';

function Testimonials() {
 document.title = "Casos de sucesso";
  return (
    <div className='testimonials-page'>
        <Navbar active={3} black /> 
        <TestimonialHeader />
        <TestimonialClient />
        <TextimonialExtra />
         <TestimonialVideos />
         <TestimonialsSection />
        <FooterSection /> 
    </div>
  )
}

export default Testimonials
