import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageLoading from './ImageLoading';
import { TfiArrowTopRight } from "react-icons/tfi";
import { FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom';


function PerformanceSection() {
   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 4000,
      slidesToShow: 2,
      slidesToScroll: 2
   };

   return (
      <section className='bg-section performance-section'>
         <div className="wrapper">
            <div className="content">
               <div className="title-info">
                  <h1 className="title-2">Para usar e obter desempenho de qualidade.</h1>
                  <p className='description-1'>
                     Solução baseada em nuvem que simplifica o gerenciamento de projetos,
                     permitindo que as equipes se concentrem na entrega de resultados de alta qualidade
                     sem as complexidades.
                  </p>
               </div>
               <div className="boxes-area">
                  <article>
                     <div className="text-el">
                        <div><h1 className="title-2">Facil Controle de dados  </h1></div>
                        <Link to="/request_budget">
                           <div className="arrow"><FiArrowRight /></div>
                        </Link>
                     </div>
                     <p className="description-1">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et labore, ipsum cum non consectetur culpa.</p>
                     <div className="box"> <ImageLoading height={300} source={"https://images.pexels.com/photos/3183188/pexels-photo-3183188.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} alt="" /> </div>
                  </article>
                  <article className='bg-main'>
                     <div className="text-el">
                        <div><h1 className="title-2">Gestão <br /> Rapida & simples</h1></div>
                        <Link to="/request_budget">
                           <div className="arrow"><FiArrowRight /></div>
                        </Link>
                     </div>
                     <p className="description-1">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et labore, ipsum cum non consectetur culpa.</p>
                     <div className="box"> <ImageLoading height={300} source={"https://images.pexels.com/photos/3183127/pexels-photo-3183127.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} alt="" /> </div>
                  </article>
                  <article>
                     <div className="text-el">
                        <div><h1 className="title-2">Manutenção & Backups anual </h1></div>
                        <Link to="/request_budget">
                           <div className="arrow"><FiArrowRight /></div>
                        </Link>
                     </div>
                     <p className="description-1">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et labore, ipsum cum non consectetur culpa.</p>
                     <div className="box"> <ImageLoading height={300} source={"https://images.pexels.com/photos/3184315/pexels-photo-3184315.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} alt="" /> </div>
                  </article>
               </div>
            </div>
         </div>
      </section>
   )
}

export default PerformanceSection
