import React from 'react';
import Navbar from '../Components/Navbar';
import FooterSection from '../Components/FooterSection';
import { LuPhone } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { LuMapPin } from "react-icons/lu";
import { LuUserCircle } from "react-icons/lu";
import ScrollToTop from '../Components/ScrollToTop';

function Contacts() {
  document.title = "Contactos";
  return (
    <div>
      <Navbar active={4} black /> 
      <br />
      <div className="contact-section">
        <div className="wrapper">
          <div className="contact-area">
            <div className="title-info">
              <h1 className="title-1">Contacte-nos</h1>
              <div className="desc-area">
                <div className="line">
                  <div className="arrow"></div>
                </div>
                <div className="p-box">
                  <p className="description-1">
                    Usar um software de gestão de recursos humanos  conciso e
                    eficaz é crucial para delinear seu negócio e seus serviços.
                  </p>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="boxes">
                <div className="box bg-section">
                  <h1 className="title-3">Sede Lisboa </h1>
                  <div className="flex-item">
                    <div className="icon"><LuPhone /></div>
                    <div className="block">
                      <a href="tel:+351210353555"><p className="description-1">(+351) 210 353 555</p></a>
                      <a href="tel:+351212456082"><p className="description-1">(+351) 212 456 082</p></a>
                    </div>
                  </div>
                  <div className="flex-item">
                    <div className="icon"><HiOutlineMail /></div>
                    <div className="block">
                      <a href="mailto:geral@exportech.com.pt"><p className="description-1">geral@exportech.com.pt</p></a>
                     <a href="mailto:helpdesk@exportech.com.pt"><p className="description-1">helpdesk@exportech.com.pt</p></a>
                    </div>
                  </div>
                  <div className="flex-item">
                    <div className="icon"><LuMapPin /></div>
                    <div className="block">
                      <p className="description-1">Rua Fernando Farinha nº 2A e 2B
                        Braço de Prata - Lisboa 1950-448</p>
                    </div>
                  </div>
                </div>
                <div className="box bg-section">
                  <h1 className="title-3">Suporte Técnico</h1>
                  <div className="flex-item">
                    <div className="icon"><LuUserCircle /></div>
                    <div className="block">
                      <a href="tel:+351291601603"><p className="description-1">(+351) 291 601 603</p></a>
                      <a href="mailto:helpdesk@exportech.com.pt"><p className="description-1">helpdesk@exportech.com.pt</p></a>
                    </div>
                  </div>
                  <div className="flex-item">
                    <div className="icon"><LuUserCircle /></div>
                    <div className="block">
                     <a href="tel:+351927975712"><p className="description-1">(+351) 927 975 712</p></a>
                     <a href="mailto:beto.silva@exportech.com.pt"> <p className="description-1">beto.silva@exportech.com.pt</p> </a>
                    </div>
                  </div>
                  <div className="flex-item">
                    <div className="icon"><LuUserCircle /></div>
                    <div className="block">
                      <a href="tel:+351210353555"><p className="description-1">(+351) 210 353 555</p></a>
                      <a href="mailto:ivo.ferreira@exportech.com.pt"><p className="description-1">ivo.ferreira@exportech.com.pt</p></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-box">
                <div className="bg-main">
                  <iframe frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=exportech&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
                </div>
                <div className="dets">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  )
}

export default Contacts
