 
async function SendEmail( nif, sector, company , name, phone, email, message){ 
    try {
        const message =  ({
            text:message,
            from:'helpdesk@exportech.com.pt',
            to:'kiosso.silva@exportech.com.pt',
            cc:'',
            subject:'Solicitação de Orçamento 2Smart -  por '+ name + " da empresa "+company+ " com o Nif: "+nif,
        }); 

        return true;
  
    } catch (err) {
        console.log("ERROR ----------------------------------------*****")
        console.error(err);
        return false;
    }
}

export default SendEmail
