import React from 'react';
import { FaCheck } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";
import ImageLoading from './ImageLoading';
import { LuLayoutDashboard } from "react-icons/lu";
import { FaNetworkWired } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import { LiaCogSolid } from "react-icons/lia";
import { GiInfo } from "react-icons/gi";
import { MdEdit } from "react-icons/md";
import { TbClockHour8 } from "react-icons/tb";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { IoTrashBinOutline } from "react-icons/io5";
import { LuTrash2 } from "react-icons/lu";
import { FaRegDotCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { FaFingerprint } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Assistent() {


   const Users = [
      {
         name: "Pedro Mateus",
         status: false,
         pic: "https://media.istockphoto.com/id/1082483460/photo/beautiful-black-man.jpg?s=612x612&w=0&k=20&c=MmNFcZf6z2WLY7jMBAmtLxo6YNItudiRuzn-z7V3tZk="
      },
      {
         name: "Sara Fernando",
         status: true,
         pic: "https://img.freepik.com/free-photo/african-american-woman-smiling-mockup-psd-cheerful-expression-cl_53876-148125.jpg"
      },
      {
         name: "Carolina Morais",
         status: true,
         pic: "https://img.freepik.com/free-photo/cheerful-dark-skinned-woman-smiling-broadly-rejoicing-her-victory-competition-among-young-writers-standing-isolated-against-grey-wall-people-success-youth-happiness-concept_273609-1246.jpg"
      },
      {
         name: "André Gomez",
         status: true,
         pic: "https://media.istockphoto.com/id/1081381240/photo/young-smiling-african-american-man-over-white-background.jpg?s=612x612&w=0&k=20&c=T2Mq5yJ93H5jvbI87tC5RjXuGcmDdTH4GzcyOL_WRl4="
      },
      {
         name: "Pascoal Lopes",
         status: false,
         pic: "https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg"
      },
      {
         name: "Pitter Joe",
         status: false,
         pic: "https://t3.ftcdn.net/jpg/02/22/85/16/360_F_222851624_jfoMGbJxwRi5AWGdPgXKSABMnzCQo9RN.jpg"
      },
      {
         name: "Felix Mateus",
         status: true,
         pic: "https://www.studio-pop-art.fr/cdn/shop/products/portrait-homme-465158.webp?v=1690383976&width=1445"
      },
   ];

   const Data = [
      {
         title: "Dashboard custumizado",
         option1: "Visualização  das informações gerais da aplicação",
         option2: "Visualização de dados de nível estatístico.",
         bg: "bg-green",
         mock: <div className="mockbox-dash">
            <div className="boxes">
               <div className="box box-blue">
                  <div className="space-item">
                     <div><span>Total utilizadores</span> </div>
                     <div><div className="icon"><HiOutlineCog6Tooth /></div></div>
                  </div>
                  <div className="chart-box">
                     <div className="st-box">
                        <div className="status">45%</div>
                     </div>
                     <div class="arc"></div>
                  </div>
                  <div className="space-item mt-n">
                     <div className='flex-item'> </div>
                     <div className='flex-item'>
                        <div className="icon"><FiEdit3 /></div>
                        <div className="icon"><TbClockHour8 /></div>
                     </div>
                  </div>
               </div>
               <div className="box box-green">
                  <div className="space-item">
                     <div><span>Impressões Digitais</span> </div>
                     <div><div className="icon"><HiOutlineCog6Tooth /></div></div>
                  </div>
                  <div className="chart-box">
                     <div className="st-box">
                        <div className="status">74%</div>
                     </div>
                     <div class="arc"></div>
                  </div>
                  <div className="space-item mt-n">
                     <div className='flex-item'> </div>
                     <div className='flex-item'>
                        <div className="icon"><FiEdit3 /></div>
                        <div className="icon"><TbClockHour8 /></div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="table-box">
               <div className="title">
                  <div className="flex-item">
                     <FaFingerprint />
                     <span>Dispositivos</span>
                  </div>
               </div>
               <hr />
               <Table responsive size="sm">
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Modelo</th>
                        <th>Conexão</th>
                        <th>Fabricante</th>
                        <th>Nº Série</th>
                        <th>Ação</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>1</td>
                        <td>Face ID3</td>
                        <td>
                           <div className="text-main"> 
                              <GiNetworkBars />
                           </div> 
                        </td>
                        <td>Fingertec</td> 
                        <td>038943</td>
                        <td>
                           <div className="flex-item">
                              <div className="icon"><FiEdit3 /></div>
                              <div className="icon"><LuTrash2 /></div>
                           </div>
                        </td>
                     </tr>
                     <tr>
                        <td>2</td>
                        <td>TA500R</td>
                        <td>
                           <div className="text-danger"> 
                              <GiNetworkBars />
                           </div> 
                        </td>
                        <td>Fingertec</td> 
                        <td>467433</td>
                        <td>
                           <div className="flex-item">
                              <div className="icon"><FiEdit3 /></div>
                              <div className="icon"><LuTrash2 /></div>
                           </div>
                        </td>
                     </tr>
                  </tbody>
               </Table>
            </div>
         </div>
      },
      {
         title: "Gestão de Horários",
         option1: "Consultar os horários atribuídos aos Colaboradores.",
         option2: "Navegação mensal no Mapa de Trabalho.",
         mock: <div className="mockbox-dash">
            <div className="title"></div>
            <div className="table-box users-table">
               <Table size="sm">
                  <thead>
                     <tr>
                        <th><div className="icon"><FaRegDotCircle /></div></th>
                        <th>Colaborador</th>
                        <th>Estado</th>
                        <th>Ações</th>
                     </tr>
                  </thead>
                  <tbody>
                     {Users.map((item, index) => (
                        <tr>
                           <td><div className="icon mt-1"><FaRegCircle /></div></td>
                           <td>
                              <div className="flex-item">
                                 <div className="pic">
                                    <ImageLoading source={item.pic} />
                                 </div>
                                 <span>{item.name}</span>
                              </div>
                           </td>
                           <td>
                              <div className="flex-item mt-2">
                              <Form.Check type="switch" checked={item.status} id="custom-switch"      /> 
                                 <span>{item.status === false ? " Inactivo" : " Activo"}</span>
                              </div>
                           </td>
                           <td>
                              <div className="flex-item">
                                 <div className="icon"><FiEdit3 /></div>
                                 <div className="icon"><LuTrash2 /></div>
                              </div>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </div>
         </div>
      },
   ];


   return (
      <section className=" assistent-section">
         <div className="wrapper">
            <div className="content">
               <div className="title-items">
                  <h1 className="title-2">Conheça seu assistente inteligente.</h1>
                  <p>Simplifica o  gestão de recursos humanos, a atribuição de tarefas e o acompanhamento, garantindo que as equipes permaneçam
                     organizado e cumprindo prazos.</p>
               </div>
               <div className="wrap-items">
                  {Data.map((item, index) => (
                     <article className={`card-${index + 1} ${item.class}`} >
                        <div className="dets">
                           <h1 className="title-4">{index + 1}. {item.title}</h1>
                           <p className="description-1"><FaCheck /> {item.option1}</p>
                           <p className="description-1"><FaCheck /> {item.option2}</p>
                        </div>
                        <div className={`image-box bg-section`}>
                           {item.mock}
                        </div>
                        <div className="button-container">
                        <Link to="/request_budget">
                           <button className="btn bg-main">Compre Agora</button> 
                        </Link>
                        </div>
                     </article>
                  ))}
               </div>
            </div>
         </div> 
      </section>
   )
}

export default Assistent
