import React, { useEffect, useState } from 'react'
import { AiFillThunderbolt } from "react-icons/ai";
import { FaCirclePlay } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import ImageLoading from './ImageLoading';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

function Header() {
  const [Position, setPosition] = useState(false);
  const [ShowScrollItem, setShowScrollItem] = useState(false)
  const [DH, setDH] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  function MoveFrame() {
    window.addEventListener("scroll", (e) => {
      let height = document.body.offsetHeight > 4000 ? (document.body.offsetHeight - 1050) : document.body.offsetHeight;
      let scroll = window.scrollY;
      const val = Math.floor(((scroll + 200) * 100) / height);

      setDH(val)

      setPosition(Math.floor(window.scrollY) > 400 ? true : false);
    });
    window.addEventListener("scroll", (e) => {
      setShowScrollItem(Math.floor(window.scrollY) > 500 ? true : false);
    });
  }

  useEffect(() => {
    MoveFrame();
  }, []);



  return (
    <div className='header-main'>
      <Modal show={show} fullscreen size='xl' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> <h5 className='text-white'>2Smart Apresentação</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-presentation">
            <iframe src="https://www.youtube.com/embed/KDwhpMY-WJ0?si=dG_WhAl4loVDdKmM" title="YouTube video player"
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </Modal.Body>
      </Modal>
      <section className='bg-black  header'>

        <div className="wrapper">
          <div className="content h1">

 


            <div className="box-info"><AiFillThunderbolt /> <span>As ferramentas mais poderosas</span></div>
            <h1 className="title-1">Para trabalhar rapidamente e colaborar com equipas</h1>
            <p className='description-0'>
              uma Plataforma de Gestão de Assiduidades e Acessos acessivel através de um browser,
              Especialmente concebida para empresas .
            </p>
            <div className="buttons">
              <Link to="/request_budget"><button className="btn text-white bg-main">Comprar Agora</button></Link>
              <button onClick={handleShow} className="btn bg-white"><FaCirclePlay /> Assista o Video</button>



              <div className="ani-el an-1">
              <div className="block-ln  b1">
                <div className="ln ln-1"> <div className="dot"></div></div>
                <div className="ln ln-2"> <div className="dot"></div></div>
                <div className="ln ln-3"> <div className="dot"></div></div>
                <div className="ln ln-4"> <div className="dot"></div></div>
              </div>
              <div className="block-ln b2">
                <div className="ln ln-1"> <div className="dot"></div></div>
                <div className="ln ln-2"> <div className="dot"></div></div>
                <div className="ln ln-3"> <div className="dot"></div></div>
                <div className="ln ln-4"> <div className="dot"></div></div>
              </div>
            </div>

            <div className="ani-el an-2"> 
              <div className="block-ln b2">
                <div className="ln ln-1"> <div className="dot"></div></div>
                <div className="ln ln-2"> <div className="dot"></div></div>
                <div className="ln ln-3"> <div className="dot"></div></div>
                <div className="ln ln-4"> <div className="dot"></div></div>
              </div>
              <div className="block-ln  b1">
                <div className="ln ln-1"> <div className="dot"></div></div>
                <div className="ln ln-2"> <div className="dot"></div></div>
                <div className="ln ln-3"> <div className="dot"></div></div>
                <div className="ln ln-4"> <div className="dot"></div></div>
              </div>
            </div>



            </div>
            <p className="description-0"><small>*Gestão de recursos humanos sem a necessidade de usar o papel ou softwares externos</small></p>
          </div>
        </div>
        <div className="wrapper">
          <div className="content">
            <div className={Position ? "framer-app nm" : "framer-app"}>
              <div className="frame-header">
                <div className="dot d1"></div>
                <div className="dot d2"></div>
                <div className="dot d3"></div>
              </div>
              <div className="cover">
                <ImageLoading height={490} source={window.innerWidth <= 900 ?
                 require("../Assets/Images/frame1.jpg") :
                 require("../Assets/Images/frame.jpg") } alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Header
