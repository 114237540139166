import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageLoading from '../ImageLoading';


function TestimonialClient() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows:false,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
         {
           breakpoint: 1024,
           settings: {
             slidesToShow: 4,
             slidesToScroll:4,
             infinite: true,
             dots: true
           }
         },
         {
           breakpoint: 600,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 2,
             initialSlide: 2
           }
         },
         {
           breakpoint: 480,
           settings: {
             slidesToShow: 3,
             slidesToScroll: 3
           }
         }
       ]
     };
     
  const Data = [
    {image:"https://bricomarchecdnprd.azureedge.net/wp-content/themes/bricomarche/dist/images/bricomarche_2024_084b42bc.svg"},  
    {image:"https://casaaraujograciosa.pt/storage/cT5ALDcxe4/logo/logo.png?v=1539961781"},  
    {image:"https://www.cassianosrentacar.com/storage/app/media/rent%20a%20car_namePrancheta%201%20copy%203.png"}, 
    {image:"https://www.mercadinhodosacores.pt/wp-content/uploads/2016/04/logo-preto_branco.jpg"}, 
    {image:"https://desentop.com/wp-content/uploads/2023/09/logo_desentop_RGB_positivo-2.svg"}, 
    {image:"https://de.cdn-website.com/2aebd13aba8148b9bed8bce5b1152180/DESKTOP/png/068.png"},  
  ];

  return (
    <div className='testimonial-customers'>
       <div className="wrapper">
          <div className="content">
          <Slider  {...settings} >
             {Data.map((item, index)=>(
                <article  key={index}><ImageLoading height={50} source={item.image} className="image-item" /></article>
             ))} 
          </Slider> 
          </div>
       </div>
    </div>
  )
}

export default TestimonialClient
