import React from 'react'
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function TestimonialVideos() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  

  const Data = [ 

    {
      video:"https://www.youtube.com/embed/Jw7s42Op2ao?si=CBzdGPWdZckquMvQ",
      name:"Pedro Lopes",
      charge:"Engenheiro de software na",
      company:"Olisipo",
    }, 
    {
      video:"https://www.youtube.com/embed/8LSt8_11wbQ?si=Cxa3XD2mFQ7bjugw",
      name:"Sandra Manuel",
      charge:"Comercial Senior na",
      company:"rumos",
    },   
    {
      video:"https://www.youtube.com/embed/O9izUL_fc9I?si=6_Nkzv2Z-GZvZPU2",
      name:"Fernando Garcia",
      charge:"Diretor de recursos Humanos na ",
      company:"Cisco",
    },    
  ];



  return (
    <div className='testimonials-video'>
      <div className="wrapper">
        <div className="content">
          <div className="center">
            <h1 className="title-2">Os nossos clientes falam por nós</h1>
            <p className='description-1'>Esses recursos contribuem coletivamente para um projeto completo Sistema de gestão que ajuda as equipes a planejar e executar com eficiência.</p>
          </div>
          <section>
            <div className="slider-container">
              <Slider  arrows={true}   swipeable={true} draggable={false}
              showDots={true}  infinite={true} responsive={responsive}>
              {Data.map((item, index) => (
                 <article>
                 <div className="video-test-box">
                   <div className="text-box">
                     <div><h3>{item.name}</h3></div>
                     <div><span>{item.charge} <span>#{item.company}</span></span></div>
                   </div>
                   <div className="video-box">
                     <div className="frame-zone">
                       <iframe src={item.video} title="YouTube video player" frameborder="0"
                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                     </div>
                     <br />
                     <small>#Amantes do Software 2smart a volta do país </small>
                   </div>
                 </div>
               </article>))} 
              </Slider>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default TestimonialVideos
